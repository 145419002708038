<template>
  <div>
    <h3>{{ localize(value.title) }}</h3>
    <p>{{ localize(value.subtitle) }}</p>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  }
};
</script>
