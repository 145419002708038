<template>
  <div style="width: 100%">
    <h3 v-show="!editMode" @click="edit">{{ localize(displayText) }}</h3>
    <locale-text-field
      v-show="editMode"
      v-model="value.title"
      ref="editField"
      :placeholder="$t('forms.enterHeadline')"
      class="pa-0"
      solo
      dense
      flat
      @blur="editMode = false"
    ></locale-text-field>
  </div>
</template>

<script>
import localeTextField from '@/components/misc/localization-text-field.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    editMode: false
  }),

  computed: {
    displayText() {
      if (
        this.value.title !== undefined &&
        this.value.title.filter((x) => x.translation.trim() !== '').length > 0
      ) {
        return this.value.title;
      }

      return this.$t('forms.enterHeadline');
    }
  },

  watch: {
    editMode() {
      this.$emit('editModeChanged', this.editMode);
    }
  },

  created() {
    this.setFocus();

    if (this.value.init) {
      this.edit();
    }
  },

  methods: {
    edit() {
      this.editMode = true;
      this.setFocus();
    },

    setFocus() {
      setTimeout(() => {
        this.$refs.editField.focus();
      }, 300);
    }
  },

  components: {
    localeTextField
  }
};
</script>

<style lang="scss" scoped>
h1 {
  min-height: 20px;
}

h1:hover {
  cursor: pointer;
}

.v-input::v-deep .v-input__slot {
  margin: 0;
}

.v-input::v-deep .v-text-field__details {
  display: none;
}
</style>
