<template>
  <div v-if="formData !== null">
    <v-row dense>
      <v-col cols="12" md="8">
        <locale-text-field
          v-model="formData.name"
          :label="$t('forms.formName')"
          filled
        ></locale-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          v-model="formData.type"
          :label="$t('forms.formType')"
          :items="formTypes"
          item-text="label"
          item-value="value"
          filled
        ></v-select>
      </v-col>
    </v-row>

    <hr />

    <v-row dense>
      <v-col cols="12" sm="6" md="4">
        <h3>{{ $t('forms.pages') }}</h3>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" sm="12" md="12">
        <form-pages
          v-model="formData.pages"
          @addPage="addPage"
          @removePage="removePage"
        ></form-pages>
      </v-col>
    </v-row>

    <v-row dense class="mt-4">
      <v-col cols="4">
        <v-btn :to="{ name: 'FormsOverview' }" active-class="no-active">
          <v-icon class="mr-2">mdi-arrow-left</v-icon>
          {{ $t('back') }}
        </v-btn>
      </v-col>
      <v-col cols="8" class="text-right">
        <v-btn
          v-if="mode === 'create'"
          class="mr-2"
          @click="saveDraft"
          :loading="formSaving"
        >
          <v-icon class="mr-2">mdi-floppy</v-icon>
          {{ $t('forms.saveDraft') }}
        </v-btn>

        <v-btn
          class="mr-2"
          :to="{ name: 'FormPreview', params: { id: this.formData.id } }"
        >
          <v-icon class="mr-2">mdi-eye</v-icon>
          {{ $t('forms.preview') }}
        </v-btn>

        <v-btn
          color="secondary"
          class="mr-2"
          @click="save"
          :loading="formPublishing"
        >
          <v-icon class="mr-2">mdi-check</v-icon>
          <template v-if="mode === 'create'">{{ $t('create') }}</template>
          <template v-else>{{ $t('save') }}</template>
        </v-btn>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="successSnackbar"
      color="success"
      :timeout="snackbarTimeout"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-check-circle</v-icon>
        <v-layout column>
          <div>{{ $t('toast.dataSaved') }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { feedbackEnums } from '@/enums/feedbacks.js';

import formPages from '@/components/forms/pages.vue';
import localeTextField from '@/components/misc/localization-text-field.vue';

export default {
  components: {
    formPages,
    localeTextField
  },

  props: {
    value: {
      type: Object,
      required: true
    },

    mode: {
      type: String,
      required: true
    }
  },

  data: () => ({
    formData: null,
    formSaving: false,
    formPublishing: false,
    successSnackbar: false,
    snackbarTimeout: 2000,
    customSettings: null
  }),

  computed: {
    formTypes() {
      return Object.values(feedbackEnums.processTypes).map((x) => ({
        value: x,
        label: this.$t(`feedbacks.processTypes.${x}`)
      }));
    }
  },

  async mounted() {
    this.formData = this.value;
    this.$formsHub.enterCreateForm(this.formData.correlationId);
    this.$formsHub.$on('form-saved', this.onFormSaved);
  },

  beforeDestroy() {
    this.$formsHub.leaveCreateForm(this.formData.correlationId);
    this.$formsHub.$off('form-saved', this.onFormSaved);
  },

  methods: {
    addPage() {
      this.formData.pages.push({
        id: uuidv4(),
        pageIndex: this.formData.pages.length + 1,
        elements: []
      });
    },

    onFormSaved(data) {
      if (
        data.formId === this.formData.id &&
        data.correlationId == this.formData.correlationId
      ) {
        this.formSaving = false;
        this.formPublishing = false;
        this.successSnackbar = true;
      }
    },

    removePage(pageIndex) {
      const pages = this.formData.pages.filter(
        (x) => x.pageIndex !== pageIndex
      );
      for (let i = 0; i < pages.length; i++) {
        pages[i].pageIndex = i + 1;
      }

      this.formData.pages = pages;
    },

    saveDraft() {
      this.formSaving = true;
      this.$emit('save');
    },

    save() {
      this.formPublishing = true;
      this.$emit('create');
    }
  }
};
</script>

<style lang="scss" scoped>
#form-action-buttons {
  position: fixed;
}
</style>
