var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.editMode},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('fieldset',_vm._g(_vm._b({staticClass:"form-element-wrapper pa-2 pb-0 mb-2"},'fieldset',attrs,false),on),[_c('legend',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.componentIcon))]),_vm._v(" "+_vm._s(_vm.componentLabel)+" ")],1),_c('v-sheet',{staticClass:"px-2 py-1 ma-2 mb-4",attrs:{"flat":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c(_vm.componentType,{tag:"component",on:{"editModeChanged":_vm.setEditMode},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('v-col',{staticClass:"text-right action-buttons",attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"left":"","disabled":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""},on:{"click":_vm.duplicateElement}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('forms.duplicateElement')))])]),_c('v-tooltip',{attrs:{"left":"","disabled":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.numElements > 1),expression:"numElements > 1"}],staticClass:"mr-1 drag-handle",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-drag")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('sortDown')))])]),_c('v-tooltip',{attrs:{"left":"","disabled":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""},on:{"click":_vm.deleteElement}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-forever")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('forms.deleteElement')))])])],1)],1)],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('forms.elementEditTooltip')))])])}
var staticRenderFns = []

export { render, staticRenderFns }