<template>
  <v-expansion-panels class="pa-0">
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ $t('forms.anchor') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-for="item in anchorValues" :key="`anker_${item.value}`">
          <locale-text-field
            v-model="item.translation"
            :label="$t('forms.anchorLabel', { value: item.value })"
            dense
          ></locale-text-field>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import localeTextField from '@/components/misc/localization-text-field.vue';

export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },

    minValue: {
      type: Number,
      required: false,
      default: 0
    },

    maxValue: {
      type: Number,
      required: false,
      default: 5
    }
  },

  data: () => ({
    anchorValues: []
  }),

  watch: {
    anchorValues: {
      handler() {
        this.$emit('input', this.anchorValues);
      },

      deep: true
    }
  },

  mounted() {
    this.anchorValues = this.value;
  },

  components: {
    localeTextField
  }
};
</script>
