<template>
  <v-tooltip bottom :disabled="editMode">
    <template v-slot:activator="{ on, attrs }">
      <fieldset
        class="form-element-wrapper pa-2 pb-0 mb-2"
        v-bind="attrs"
        v-on="on"
      >
        <legend>
          <v-icon class="mr-2">{{ componentIcon }}</v-icon>
          {{ componentLabel }}
        </legend>
        <v-sheet flat class="px-2 py-1 ma-2 mb-4">
          <v-row dense>
            <v-col cols="10">
              <component
                :is="componentType"
                v-model="value"
                @editModeChanged="setEditMode"
              ></component>
            </v-col>

            <v-col cols="2" class="text-right action-buttons">
              <v-tooltip left disabled>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="duplicateElement"
                    text
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('forms.duplicateElement') }}</span>
              </v-tooltip>

              <v-tooltip left disabled>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="numElements > 1"
                    text
                    v-bind="attrs"
                    v-on="on"
                    class="mr-1 drag-handle"
                  >
                    <v-icon>mdi-drag</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('sortDown') }}</span>
              </v-tooltip>

              <v-tooltip left disabled>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text @click="deleteElement" v-bind="attrs" v-on="on">
                    <v-icon>mdi-delete-forever</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('forms.deleteElement') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-sheet>
      </fieldset>
    </template>
    <span>{{ $t('forms.elementEditTooltip') }}</span>
  </v-tooltip>
</template>

<script>
import titleElement from '@/components/forms/form-elements/title-element.vue';
import textElement from '@/components/forms/form-elements/text-element.vue';
import questionElement from '@/components/forms/form-elements/question-element.vue';
import matrixElement from '@/components/forms/form-elements/question-matrix-element.vue';
import tableElement from '@/components/forms/form-elements/table-element.vue';
import tableReviewElement from '@/components/forms/form-elements/table-review-element.vue';
import targetsElement from '@/components/forms/form-elements/targets-element.vue';
import actionsElement from '@/components/forms/form-elements/actions-element.vue';
import customElement from '@/components/forms/form-elements/custom-element.vue';

import { formEnums } from '@/enums/forms.js';

export default {
  name: 'formElement',
  components: {
    titleElement,
    textElement,
    questionElement,
    matrixElement,
    tableElement,
    tableReviewElement,
    customElement,
    targetsElement,
    actionsElement
  },

  props: {
    value: {
      type: Object,
      required: true
    },

    numElements: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    elementType: formEnums.formElements,
    editMode: true
  }),

  computed: {
    componentType() {
      if (this.value.type === this.elementType.TITLE) {
        return 'titleElement';
      } else if (this.value.type === this.elementType.TEXT) {
        return 'textElement';
      } else if (this.value.type === this.elementType.QUESTION) {
        return 'questionElement';
      } else if (this.value.type === this.elementType.MATRIX) {
        return 'matrixElement';
      } else if (this.value.type === this.elementType.TABLE) {
        return 'tableElement';
      } else if (this.value.type === this.elementType.TABLE_REVIEW) {
        return 'tableReviewElement';
      } else if (this.value.type === this.elementType.GROUP) {
        return 'groupElement';
      } else if (this.value.type === this.elementType.TARGETS) {
        return 'targetsElement';
      } else if (this.value.type === this.elementType.ACTIONS) {
        return 'actionsElement';
      } else if (this.value.type === this.elementType.CUSTOM) {
        return 'customElement';
      }

      return '';
    },

    componentIcon() {
      if (this.value.type === this.elementType.TITLE) {
        return 'mdi-format-title';
      } else if (this.value.type === this.elementType.TEXT) {
        return 'mdi-format-align-center';
      } else if (this.value.type === this.elementType.GROUP) {
        return 'mdi-crop-free';
      }

      return 'mdi-chat-question-outline';
    },

    componentLabel() {
      if (this.value.type === this.elementType.TITLE) {
        return this.$t('forms.title');
      } else if (this.value.type === this.elementType.TEXT) {
        return this.$t('forms.text');
      } else if (this.value.type === this.elementType.GROUP) {
        return this.$t('forms.grouping');
      }

      return this.$tc('forms.question', 1);
    }
  },

  methods: {
    setEditMode(mode) {
      this.editMode = mode;
    },

    deleteElement() {
      this.$emit('deleteElement', this.value.index);
    },

    duplicateElement() {
      this.$emit('duplicateElement', this.value.index);
    },

    sortDown() {
      this.$emit('sortDown', this.value.index);
    },

    sortUp() {
      this.$emit('sortUp', this.value.index);
    }
  }
};
</script>

<style lang="scss" scoped>
.form-element-wrapper {
  border: 2px dotted rgba(0, 0, 0, 0.2);
  position: relative;
}

.copy-button-row {
  display: flex;
  justify-content: center;
}

.copy-button {
  position: absolute;
  bottom: -15px;
}

fieldset {
  legend {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding: 0 0.75em;
    color: var(--legend-textcolor);
    font-weight: bold;

    i.v-icon.theme--light {
      color: rgba(0, 0, 0, 0.2);
    }
  }

  .v-sheet .row .col {
    display: flex;
    align-items: center;
  }

  .action-buttons {
    justify-content: flex-end;
    align-items: flex-start !important;

    .v-btn {
      min-width: auto;
      padding: 0 0.5em;
    }
  }
}
</style>
