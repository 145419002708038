<template>
  <div v-click-outside="disableEdit" style="width: 100%">
    <div v-show="!editMode" @click="edit">
      <div>{{ itemsList }}</div>
      <div class="grey--text text--darken-1">
        {{
          $t('forms.scaleDescription', {
            min: value.minValue,
            max: value.maxValue
          })
        }}

        <span v-show="value.mandatoryScale"> {{ $t('forms.mandatory') }}</span>
        <span v-show="!value.mandatoryScale"> {{ $t('forms.optional') }}</span>
      </div>
      <div class="grey--text text--darken-1">
        <span v-show="value.allowComment">{{ $t('forms.withComment') }}</span>
        <span v-show="!value.allowComment">
          {{ $t('forms.withoutComment') }}
        </span>

        <span v-show="value.mandatoryComment">
          {{ $t('forms.mandatory') }}
        </span>
        <span v-show="!value.mandatoryComment">
          {{ $t('forms.optional') }}
        </span>
      </div>
    </div>

    <div v-show="editMode" class="pa-4">
      <h3 class="muted text-uppercase">{{ $t('settings') }}</h3>

      <v-row dense>
        <v-col cols="12">
          <v-checkbox
            v-model="value.mandatoryScale"
            :label="$t('forms.mandatoryScale')"
            color="secondary"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="6">
          <v-subheader class="px-0">
            {{ $t('forms.minValue') }}
          </v-subheader>

          <v-slider
            v-model="value.minValue"
            class="align-center"
            max="10"
            min="0"
            hide-details
            color="#009fda"
            track-color="#000"
          >
            <template v-slot:append>
              <v-text-field
                v-model="value.minValue"
                class="mt-0 pt-0"
                hide-details
                single-line
                style="width: 30px"
                disabled
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>

        <v-col cols="6">
          <v-subheader class="px-0">
            {{ $t('forms.maxValue') }}
          </v-subheader>

          <v-slider
            v-model="value.maxValue"
            class="align-center"
            max="10"
            min="0"
            hide-details
            color="#009fda"
            track-color="#000"
          >
            <template v-slot:append>
              <v-text-field
                v-model="value.maxValue"
                class="mt-0 pt-0"
                hide-details
                single-line
                disabled
                style="width: 30px"
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" md="6">
          <v-checkbox
            v-model="value.allowComment"
            color="secondary"
            :label="$t('forms.allowComment')"
          />
        </v-col>

        <v-col cols="12" md="6">
          <v-checkbox
            v-model="value.mandatoryComment"
            color="secondary"
            :label="$t('forms.mandatoryComment')"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <anchors
            v-model="value.labels"
            :minValue="this.value.minValue"
            :maxValue="this.value.maxValue"
          ></anchors>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h3 class="muted text-uppercase">{{ $tc('forms.question', 2) }}</h3>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col v-show="!questionHasItems">
          <v-alert type="info" text>
            {{ $t('forms.noQuestionsAdded') }}
          </v-alert>
        </v-col>

        <v-col
          v-for="(item, index) in items"
          :key="`matrix_item_${index}`"
          cols="12"
        >
          <div class="item-definition">
            <div class="item-definition__header d-flex">
              <div class="item-definition__header__index d-flex align-center">
                {{ index + 1 }}
              </div>

              <v-spacer><hr /></v-spacer>

              <div class="item-definition__header__action d-flex align-center">
                <v-btn text @click="removeMatrixItem(index)">
                  <v-icon>mdi-delete-forever</v-icon>
                </v-btn>
              </div>
            </div>

            <v-row>
              <v-col cols="12" md="6">
                {{ $t('forms.questionTitleLabel') }}
                <locale-text-field
                  v-model="item.title"
                  dense
                  filled
                ></locale-text-field>
              </v-col>

              <v-col cols="12" md="6">
                {{ $t('forms.questionSubtitleLabel') }}
                <locale-text-area
                  v-model="item.subtitle"
                  dense
                  filled
                  rows="2"
                ></locale-text-area>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <div class="d-flex justify-end">
        <v-btn color="primary" @click="addItem">
          <v-icon>mdi-plus</v-icon>
          {{ $t('forms.addQuestion') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import anchors from '@/components/forms/form-elements/anchors.vue';
import localeTextField from '@/components/misc/localization-text-field.vue';
import localeTextArea from '@/components/misc/localization-textarea.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    anchors,
    localeTextField,
    localeTextArea
  },

  props: {
    value: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    editMode: false,
    minValue: 0,
    maxValue: 3,
    useScale: true,
    items: []
  }),

  computed: {
    itemsList() {
      return this.items.map((x) => this.localize(x.title)).join(', ');
    },

    questionHasItems() {
      return this.items.length > 0;
    }
  },

  watch: {
    editMode() {
      this.$emit('editModeChanged', this.editMode);
    }
  },

  mounted() {
    if (this.value.items !== null && this.value.items !== undefined) {
      this.items = this.value.items;
    }

    if (this.value.init) {
      this.edit();
    }
  },

  methods: {
    addItem() {
      this.items.push({
        id: uuidv4(),
        title: [],
        subtitle: [],
        index: this.items.length + 1
      });

      this.value.items = this.items;
    },

    disableEdit() {
      this.editMode = false;
    },

    edit() {
      this.editMode = true;
    },

    removeMatrixItem(itemIndex) {
      this.items.splice(itemIndex, 1);
      this.value.items = this.items;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__slider {
  .v-slider__thumb {
    width: 24px;
    height: 24px;
    box-shadow: 0.75px 0.75px 5px 0.75px rgba(0, 159, 218, 0.4);
  }

  .v-slider__thumb:before {
    left: -6px;
    top: -6px;
  }

  .v-input__append-outer input {
    text-align: center;
  }
}

.item-definition {
  .item-definition__header {
    margin-bottom: 1em;
  }

  .item-definition__header:after {
    content: ' ';
    display: block;
    border-top: 2px dotted rgba(0, 0, 0, 0.2);
    margin-top: -1.5em;
    z-index: -1;
  }

  .item-definition__header__index {
    font-weight: bold;
    font-size: 1.2em;
    color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-shrink: 0;
  }

  .item-definition__header__action {
    background-color: #fff;
    display: flex;
    flex-shrink: 0;

    .v-btn {
      display: inline;
      min-width: auto;
      padding: 0 0.25em;
    }
  }

  hr {
    margin-top: 1rem;
    margin-left: 0.25rem;
  }
}

::v-deep.v-text-field--filled.theme--light .v-input__slot {
  background-color: var(--element-background);
}
</style>
