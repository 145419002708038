<template>
  <fieldset class="form-element-wrapper pa-2 pt-8 pb-0">
    <legend>
      <v-icon class="mr-2">mdi-crop-free</v-icon>
      {{ $t('forms.grouping') }}
    </legend>

    <v-sheet flat class="px-2 py-1 ma-2 mb-4">
      <v-row>
        <v-col cols="8">
          <locale-text-field
            v-model="value.title"
            :label="$t('forms.groupingHeadline')"
          ></locale-text-field>
        </v-col>
        <v-col cols="2" offset="2" class="text-right action-buttons">
          <v-tooltip left disabled>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="duplicateElement" text v-bind="attrs" v-on="on">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('forms.duplicateElement') }}</span>
          </v-tooltip>

          <v-tooltip left disabled>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on" class="mr-1 drag-handle">
                <v-icon>mdi-drag</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('sortDown') }}</span>
          </v-tooltip>

          <v-tooltip left disabled>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text @click="deleteGroup" v-bind="attrs" v-on="on">
                <v-icon>mdi-delete-forever</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('forms.deleteElement') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="8">
          <h3 class="text-uppercase muted mt-4">{{ $t('forms.condition') }}</h3>
          <v-switch
            color="secondary"
            v-model="value.hasCondition"
            :label="$t('forms.groupingWithCondition')"
          ></v-switch>

          <locale-text-field
            v-model="value.condition"
            :label="$t('forms.conditionInfo')"
            :disabled="!value.hasCondition"
          ></locale-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3 class="text-uppercase muted">{{ $t('forms.elements') }}</h3>
          <div class="component-zone pa-4 my-2">
            <draggable :list="value.elements" handle=".drag-handle">
              <div
                v-for="(element, index) in value.elements"
                :key="`form-element-${index}`"
              >
                <form-element
                  :num-elements="value.elements ? value.elements.length : 0"
                  v-model="value.elements[index]"
                  @duplicateElement="duplicateChildElement"
                  @deleteElement="deleteElement"
                ></form-element>
              </div>
            </draggable>

            <div
              v-if="!value.elements || value.elements.length === 0"
              class="text-center overline grey--text text--darken-1 mb-2"
            >
              {{ $t('forms.noElementsAdded') }}
            </div>

            <v-row dense>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  elevation="0"
                  tile
                  small
                  class="mr-2"
                  @click="addElement(elementType.TITLE)"
                >
                  <v-icon small class="mr-2">mdi-format-title</v-icon>
                  {{ $t('forms.title') }}
                </v-btn>

                <v-btn
                  elevation="0"
                  tile
                  small
                  class="mr-2"
                  @click="addElement(elementType.TEXT)"
                >
                  <v-icon small class="mr-2">mdi-format-align-center</v-icon>
                  {{ $t('forms.text') }}
                </v-btn>

                <question-type-menu
                  @addElement="addQuestion"
                ></question-type-menu>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
  </fieldset>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { formEnums } from '@/enums/forms.js';

import draggable from 'vuedraggable';
import formElement from '@/components/forms/form-element.vue';
import questionTypeMenu from '@/components/forms/question-type-menu.vue';
import localeTextField from '@/components/misc/localization-text-field.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    elementType: formEnums.formElements
  }),

  methods: {
    addElement(elementType) {
      const newElement = {
        id: uuidv4(),
        type: elementType,
        index: this.value.elements.length + 1,
        labels: [],
        title: [],
        subtitle: [],
        init: true
      };

      if (elementType === this.elementType.GROUP) {
        newElement.elements = [];
      }

      this.value.elements.push(newElement);
    },

    addQuestion(elementData) {
      elementData.index = this.value.elements.length + 1;
      elementData.init = true;
      this.value.elements.push(elementData);
    },

    deleteElement(elementIndex) {
      const elements = this.value.elements.filter(
        (x) => x.index !== elementIndex
      );

      for (let i = 0; i < elements.length; i++) {
        elements[i].index = i + 1;
      }

      this.value.elements = elements;
    },

    deleteGroup() {
      this.$emit('deleteElement', this.value.index);
    },

    duplicateElement() {
      this.$emit('duplicateElement', this.value.index);
    },

    duplicateChildElement(elementIndex) {
      let itemIndex = -1;
      for (let i = 0; i < this.value.elements.length; i++) {
        if (this.value.elements[i].index === elementIndex) {
          itemIndex = i;
          break;
        }
      }

      const element = this.value.elements[itemIndex];
      const copy = JSON.parse(JSON.stringify(element));
      copy.index = this.value.elements.length + 1;
      copy.id = uuidv4();

      for (const item of copy.items) {
        item.id = uuidv4();
      }

      this.value.elements.push(copy);
    }
  },

  components: {
    formElement,
    questionTypeMenu,
    draggable,
    localeTextField
  }
};
</script>

<style lang="scss" scoped>
fieldset {
  border: 2px dotted rgba(0, 0, 0, 0.2);

  legend {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding: 0 0.75em;
    color: var(--legend-textcolor);
    font-weight: bold;
    position: absolute;
    top: 1em;

    i.v-icon.theme--light {
      color: rgba(0, 0, 0, 0.2);
    }
  }

  .action-buttons {
    justify-content: flex-end;
    align-items: flex-start !important;

    .v-btn {
      min-width: auto;
      padding: 0 0.5em;
    }
  }

  .component-zone {
    background-color: var(--element-background);
  }

  border: none;
  margin-left: -16px;
  margin-right: -16px;
  background-color: var(--v-background-base);
  margin-bottom: 1em;
  position: relative;
}
</style>
