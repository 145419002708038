<template>
  <div v-click-outside="disableEdit">
    <div
      v-show="!editMode"
      v-html="displayText"
      @click="edit"
      class="preview"
    ></div>
    <locale-text-area
      v-show="editMode"
      v-model="value.title"
      :placeholder="$t('forms.enterText')"
    ></locale-text-area>
  </div>
</template>

<script>
import localeTextArea from '@/components/misc/localization-textarea.vue';

export default {
  components: {
    localeTextArea
  },

  props: {
    value: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    editMode: false
  }),

  watch: {
    editMode() {
      this.$emit('editModeChanged', this.editMode);
    }
  },

  computed: {
    displayText() {
      if (this.value.title && this.localize(this.value.title).trim() !== '') {
        return this.localize(this.value.title);
      }

      return this.$t('forms.enterText');
    }
  },

  mounted() {
    if (this.value.init) {
      this.edit();
    }

    //this.setFocus();
  },

  methods: {
    edit() {
      this.editMode = true;
      //this.setFocus();
    },

    disableEdit() {
      this.editMode = false;
    },

    setFocus() {
      setTimeout(() => {
        this.$refs.editField.focus();
      }, 300);
    }
  }
};
</script>

<style lang="scss" scoped>
div {
  width: inherit;
}
div.preview {
  min-height: 20px;
}

div.preview::v-deep p {
  margin: 0;
}

div.preview:hover {
  cursor: pointer;
}

.v-input::v-deep .v-input__slot {
  margin: 0;
}

.v-input::v-deep .v-text-field__details {
  display: none;
}
</style>
