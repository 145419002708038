<template>
  <div v-click-outside="disableEdit" style="width: 100%">
    <div v-show="!editMode" @click="edit">
      <h3>{{ localize(value.title) }}</h3>
      <p>{{ localize(value.subtitle) }}</p>
      <p>
        {{ $tc('forms.tableQuestionColumns', 2) }}:
        {{ itemsList }}
      </p>
    </div>

    <div v-show="editMode" class="pa-4">
      <v-row dense>
        <v-col cols="12">
          <locale-text-field
            v-model="value.title"
            ref="titleField"
            :label="$t('forms.questionTitleLabel')"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <locale-text-area
            v-model="value.subtitle"
            :label="$t('forms.questionSubtitleLabel')"
            rows="2"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h3 class="muted text-uppercase">
            {{ $tc('forms.tableQuestionColumns', 2) }}
          </h3>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col v-show="!questionHasItems">
          <v-alert type="info" text>
            {{ $t('forms.noColumnsAdded') }}
          </v-alert>
        </v-col>
      </v-row>

      <v-row
        v-for="(item, index) in sortedItems"
        :key="`table_question_item_${index}`"
        dense
      >
        <v-col cols="12">
          <div class="item-definition">
            <div class="item-definition__header d-flex">
              <div class="item-definition__header__index d-flex align-center">
                {{ index + 1 }}
              </div>

              <v-spacer><hr /></v-spacer>

              <div class="item-definition__header__action d-flex align-center">
                <v-btn text @click="removeItem(index)">
                  <v-icon>mdi-delete-forever</v-icon>
                </v-btn>
              </div>
            </div>

            <v-row>
              <v-col cols="12">
                {{ $t('forms.questionTitleLabel') }}
                <locale-text-field
                  v-model="item.title"
                  dense
                  filled
                ></locale-text-field>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <div class="d-flex justify-end">
        <v-btn color="primary" @click="addItem">
          <v-icon>mdi-plus</v-icon>
          {{ $t('forms.addQuestion') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import localeTextField from '@/components/misc/localization-text-field.vue';
import localeTextArea from '@/components/misc/localization-textarea.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    editMode: false,
    items: []
  }),

  computed: {
    itemsList() {
      return this.sortedItems.map((x) => this.localize(x.title)).join(', ');
    },

    sortedItems() {
      return [...this.items].sort((a, b) => {
        if (a.index < b.index) {
          return -1;
        } else if (a.index > b.index) {
          return 1;
        } else {
          return 0;
        }
      });
    },

    questionHasItems() {
      return this.items.length > 0;
    }
  },

  created() {
    this.setFocus();

    if (this.value.init) {
      this.edit();
    }
  },

  mounted() {
    if (this.value.items !== null && this.value.items !== undefined) {
      this.items = this.value.items;
    }

    if (this.value.init) {
      this.edit();
    }
  },

  methods: {
    addItem() {
      this.items.push({
        id: uuidv4(),
        title: '',
        index: this.items.length + 1
      });

      this.value.items = this.items;
    },

    disableEdit() {
      this.editMode = false;
    },

    edit() {
      this.editMode = true;
      this.setFocus();
    },

    setFocus() {
      setTimeout(() => {
        this.$refs.titleField.focus();
      }, 300);
    },

    removeItem(itemIndex) {
      this.items.splice(itemIndex, 1);
      this.value.items = this.items;
    }
  },

  components: {
    localeTextField,
    localeTextArea
  }
};
</script>

<style lang="scss" scoped>
.item-definition {
  .item-definition__header {
    margin-bottom: 1em;
  }

  .item-definition__header:after {
    content: ' ';
    display: block;
    border-top: 2px dotted rgba(0, 0, 0, 0.2);
    margin-top: -1.5em;
    z-index: -1;
  }

  .item-definition__header__index {
    font-weight: bold;
    font-size: 1.2em;
    color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-shrink: 0;
  }

  .item-definition__header__action {
    background-color: #fff;
    display: flex;
    flex-shrink: 0;

    .v-btn {
      display: inline;
      min-width: auto;
      padding: 0 0.25em;
    }
  }

  hr {
    margin-top: 1rem;
    margin-left: 0.25rem;
  }
}

::v-deep.v-text-field--filled.theme--light .v-input__slot {
  background-color: var(--element-background);
}
</style>
