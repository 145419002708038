<template>
  <v-sheet rounded class="pa-4 mb-4" min-height="150">
    <v-row dense class="mb-2">
      <v-col cols="12" class="text-right">
        <element-menu
          @addElement="addElement"
          @addQuestion="addQuestion"
          @removePage="removePage"
        ></element-menu>
      </v-col>
    </v-row>

    <draggable :list="sortedElements" handle=".drag-handle" @change="sorted">
      <div
        v-for="(element, index) in sortedElements"
        :key="`form-element-${index}`"
        class="pb-2"
      >
        <template v-if="element.type === elementType.GROUP">
          <group-element
            v-model="sortedElements[index]"
            :sortedElements="element.elements"
            @deleteElement="deleteElement"
            @duplicateElement="duplicateElement"
          ></group-element>
        </template>
        <template v-else>
          <form-element
            :num-elements="sortedElements.length"
            v-model="sortedElements[index]"
            @deleteElement="deleteElement"
            @duplicateElement="duplicateElement"
          ></form-element>
        </template>
      </div>
    </draggable>

    <div
      v-show="value.elements.length === 0"
      class="text-center overline grey--text text--darken-1 my-16"
    >
      {{ $t('forms.noElementsAdded') }}
    </div>

    <v-row dense class="mb-2">
      <v-col cols="12" class="text-right">
        <element-menu
          @addElement="addElement"
          @addQuestion="addQuestion"
          @removePage="removePage"
        ></element-menu>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { formEnums } from '@/enums/forms.js';
import { v4 as uuidv4 } from 'uuid';

import draggable from 'vuedraggable';
import formElement from '@/components/forms/form-element.vue';
import groupElement from '@/components/forms/group-element.vue';
import elementMenu from '@/components/forms/element-menu.vue';

export default {
  components: {
    formElement,
    groupElement,
    elementMenu,
    draggable
  },

  props: {
    value: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    elementType: formEnums.formElements
  }),

  computed: {
    sortedElements() {
      return this.value.elements;
    }
  },

  methods: {
    removePage() {
      this.$emit('removePage', this.value.pageIndex);
    },

    addElement(elementType) {
      const newElement = {
        id: uuidv4(),
        type: elementType,
        index: this.value.elements.length + 1,
        labels: [],
        title: [],
        subtitle: [],
        anchors: [],
        init: true
      };

      if (elementType === this.elementType.GROUP) {
        newElement.elements = [];
      }

      this.value.elements.push(newElement);
    },

    addQuestion(elementData) {
      elementData.index = this.value.elements.length + 1;
      elementData.init = true;
      this.value.elements.push(elementData);
    },

    deleteElement(elementIndex) {
      const elements = this.value.elements.filter(
        (x) => x.index !== elementIndex
      );

      for (let i = 0; i < elements.length; i++) {
        elements[i].index = i + 1;
      }

      this.value.elements = elements;
    },

    duplicateElement(elementIndex) {
      let itemIndex = -1;
      for (let i = 0; i < this.value.elements.length; i++) {
        if (this.value.elements[i].index === elementIndex) {
          itemIndex = i;
          break;
        }
      }

      const element = this.value.elements[itemIndex];
      const copy = JSON.parse(JSON.stringify(element));
      copy.index = this.value.elements.length + 1;
      copy.id = uuidv4();

      for (const item of copy.items) {
        item.id = uuidv4();
      }

      for (const childElement of copy.elements) {
        childElement.id = uuidv4();
        for (const item of childElement.items) {
          item.id = uuidv4();
        }
      }

      this.value.elements.push(copy);
    },

    sorted() {}
  }
};
</script>
