<template>
  <div v-click-outside="disableEdit" style="width: 100%">
    <div v-show="!editMode" @click="edit">
      <h3>{{ value.title }}</h3>
      <p>{{ value.subtitle }}</p>
      <p>{{ $t('forms.reviewField') }}: {{ value.referencedElementName }}</p>
    </div>

    <div v-show="editMode" class="pa-4">
      <v-row dense>
        <v-col cols="12">
          <locale-text-field
            v-model="value.title"
            ref="titleField"
            :label="$t('forms.questionTitleLabel')"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <locale-text-area
            v-model="value.subtitle"
            :label="$t('forms.questionSubtitleLabel')"
            rows="2"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="value.referencedElementName"
            :label="$t('forms.tableReviewLabel')"
          ></v-text-field
        ></v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import localeTextField from '@/components/misc/localization-text-field.vue';
import localeTextArea from '@/components/misc/localization-textarea.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    editMode: false,
    referencedElement: ''
  }),

  created() {
    this.setFocus();

    if (this.value.init) {
      this.edit();
    }
  },

  mounted() {
    if (this.value.init) {
      this.edit();
    }
  },

  methods: {
    disableEdit() {
      this.editMode = false;
    },

    edit() {
      this.editMode = true;
      this.setFocus();
    },

    setFocus() {
      setTimeout(() => {
        this.$refs.titleField.focus();
      }, 300);
    }
  },

  components: {
    localeTextField,
    localeTextArea
  }
};
</script>

<style lang="scss" scoped>
::v-deep.v-text-field--filled.theme--light .v-input__slot {
  background-color: var(--element-background);
}
</style>
