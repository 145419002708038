<template>
  <div>
    <div v-for="(page, index) in value" :key="`form_page_${index}`">
      <form-page v-model="value[index]" @removePage="removePage"></form-page>
    </div>

    <v-row>
      <v-col cols="12" class="text-center">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small fab v-bind="attrs" v-on="on" @click="addPage">
              <v-icon small>mdi-file-plus</v-icon>
            </v-btn>
          </template>
          {{ $t('forms.addPage') }}
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import formPage from '@/components/forms/page.vue';

export default {
  components: {
    formPage
  },

  props: {
    value: {
      type: Array,
      required: true
    }
  },

  methods: {
    addPage() {
      this.$emit('addPage');
    },

    removePage(index) {
      this.$emit('removePage', index);
    }
  }
};
</script>
