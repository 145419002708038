<template>
  <div v-click-outside="disableEdit" style="width: 100%">
    <div v-show="!editMode" @click="edit">
      <h3>{{ localize(value.title) }}</h3>
      <p v-html="localize(value.subtitle)"></p>
      <div v-show="value.useScale" class="grey--text text--darken-1">
        {{
          $t('forms.scaleDescription', {
            min: value.minValue,
            max: value.maxValue
          })
        }}

        <span v-show="value.mandatoryScale"> {{ $t('forms.mandatory') }}</span>
        <span v-show="!value.mandatoryScale"> {{ $t('forms.optional') }}</span>
      </div>
      <div class="grey--text text--darken-1">
        <span v-show="value.allowComment">{{ $t('forms.withComment') }}</span>
        <span v-show="!value.allowComment">
          {{ $t('forms.withoutComment') }}
        </span>

        <span v-show="value.mandatoryComment">
          {{ $t('forms.mandatory') }}
        </span>
        <span v-show="!value.mandatoryComment">
          {{ $t('forms.optional') }}
        </span>
      </div>
    </div>

    <div v-show="editMode" class="pa-4">
      <h3 class="muted text-uppercase">{{ $t('settings') }}</h3>
      <v-row dense>
        <v-col cols="12">
          <locale-text-field
            v-model="value.title"
            ref="titleField"
            :label="$t('forms.questionTitleLabel')"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <locale-text-area
            v-model="value.subtitle"
            :label="$t('forms.questionSubtitleLabel')"
            :rows="2"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <locale-text-area
            v-model="value.tooltip"
            :label="$t('forms.tooltip')"
            :rows="2"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" md="6">
          <v-checkbox
            v-model="value.useScale"
            :label="$t('forms.useScale')"
            color="secondary"
          />
        </v-col>

        <v-col cols="12" md="6">
          <v-checkbox
            v-model="value.mandatoryScale"
            :label="$t('forms.mandatoryScale')"
            color="secondary"
          />
        </v-col>
      </v-row>

      <v-row v-show="value.useScale" dense>
        <v-col cols="12" class="pa-0">
          <v-subheader class="px-0">
            {{ $t('forms.minValue') }}
          </v-subheader>

          <v-slider
            v-model="value.minValue"
            class="align-center"
            max="10"
            min="0"
            hide-details
          >
            <template v-slot:append>
              <v-text-field
                v-model="value.minValue"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
      </v-row>

      <v-row v-show="value.useScale" dense>
        <v-col cols="12" class="pa-0">
          <v-subheader class="px-0">
            {{ $t('forms.maxValue') }}
          </v-subheader>

          <v-slider
            v-model="value.maxValue"
            class="align-center"
            max="10"
            min="0"
            hide-details
          >
            <template v-slot:append>
              <v-text-field
                v-model="value.maxValue"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
      </v-row>

      <v-row v-show="value.useScale" dense>
        <v-col cols="12" class="pa-0">
          <anchors
            v-model="value.labels"
            :minValue="value.minValue"
            :maxValue="value.maxValue"
          ></anchors>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" md="6">
          <v-checkbox
            v-model="value.allowComment"
            :label="$t('forms.allowComment')"
            color="secondary"
          />
        </v-col>

        <v-col cols="12" md="6">
          <v-checkbox
            v-model="value.mandatoryComment"
            :label="$t('forms.mandatoryComment')"
            color="secondary"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-select
            :label="$t('forms.textFieldType')"
            v-model="value.commentFieldType"
            item-text="text"
            item-value="type"
            :items="textFieldTypes"
            :disabled="!value.allowComment"
          >
          </v-select>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import anchors from '@/components/forms/form-elements/anchors.vue';
import localeTextField from '@/components/misc/localization-text-field.vue';
import localeTextArea from '@/components/misc/localization-textarea.vue';
import { formEnums } from '@/enums/forms.js';

export default {
  components: {
    anchors,
    localeTextField,
    localeTextArea
  },

  props: {
    value: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    editMode: false,
    minValue: 0,
    maxValue: 3,
    useScale: true
  }),

  computed: {
    displayTitle() {
      if (this.value.title !== undefined && this.value.title.trim() !== '') {
        return this.value.title;
      }

      return this.$t('forms.enterQuestion');
    },

    displaySubtitle() {
      if (
        this.value.subtitle !== undefined &&
        this.value.subtitle.trim() !== ''
      ) {
        return this.value.subtitle;
      }

      return '';
    },

    textFieldTypes() {
      return Object.values(formEnums.textFieldType).map((x) => ({
        type: x,
        text: this.$t(`forms.textFieldTypeOptions.${x}`)
      }));
    }
  },

  watch: {
    editMode() {
      this.$emit('editModeChanged', this.editMode);
    }
  },

  created() {
    this.setFocus();

    if (this.value.init) {
      this.edit();
    }
  },

  methods: {
    disableEdit() {
      this.editMode = false;
    },

    edit() {
      this.editMode = true;
      this.setFocus();
    },

    setFocus() {
      setTimeout(() => {
        this.$refs.titleField.focus();
      }, 300);
    }
  }
};
</script>

<style lang="scss" scoped>
h3 {
  color: rgab(0, 0, 0, 0.4) !important;
}
</style>
