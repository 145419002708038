<template>
  <div>
    <v-btn
      elevation="0"
      tile
      small
      class="mr-2"
      @click="$emit('addElement', elementType.GROUP)"
    >
      <v-icon small class="mr-2">mdi-crop-free</v-icon>
      {{ $t('forms.grouping') }}
    </v-btn>

    <v-btn
      elevation="0"
      tile
      small
      class="mr-2"
      @click="$emit('addElement', elementType.TITLE)"
    >
      <v-icon small class="mr-2">mdi-format-title</v-icon>
      {{ $t('forms.title') }}
    </v-btn>

    <v-btn
      elevation="0"
      tile
      small
      class="mr-2"
      @click="$emit('addElement', elementType.TEXT)"
    >
      <v-icon small class="mr-2">mdi-format-align-center</v-icon>
      {{ $t('forms.text') }}
    </v-btn>

    <question-type-menu @addElement="addQuestion"></question-type-menu>

    <v-btn elevation="0" tile small class="mr-2" @click="$emit('removePage')">
      <v-icon small>mdi-delete-forever</v-icon>
      {{ $t('forms.deletePage') }}
    </v-btn>
  </div>
</template>

<script>
import questionTypeMenu from '@/components/forms/question-type-menu.vue';
import { formEnums } from '@/enums/forms.js';

export default {
  data: () => ({
    elementType: formEnums.formElements
  }),

  methods: {
    addQuestion(elementData) {
      this.$emit('addQuestion', elementData);
    }
  },

  components: {
    questionTypeMenu
  }
};
</script>
<style lang="scss" scoped>
.v-btn {
  background-color: var(--v-background-base) !important;
}
</style>
