<template>
  <v-menu offset-y transition="scale-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn elevation="0" tile small class="mr-2" v-bind="attrs" v-on="on">
        <v-icon small class="mr-2">mdi-chat-question-outline</v-icon>
        {{ $tc('forms.question', 1) }}
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item link @click="addQuestionMatrix">
        <v-list-item-icon>
          <v-icon small>mdi-matrix</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('forms.scaleMatrix') }}</v-list-item-title>
      </v-list-item>

      <v-list-item link @click="addScaleQuestion">
        <v-list-item-icon>
          <v-icon small>mdi-star</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('forms.scaleQuestion') }}</v-list-item-title>
      </v-list-item>

      <v-list-item link @click="addTextQuestion">
        <v-list-item-icon>
          <v-icon small>mdi-comment</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('forms.textQuestion') }}</v-list-item-title>
      </v-list-item>

      <v-list-item link @click="addTableQuestion">
        <v-list-item-icon>
          <v-icon small>mdi-table</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('forms.tableQuestion') }}</v-list-item-title>
      </v-list-item>

      <v-list-item link @click="addTableReviewQuestion">
        <v-list-item-icon>
          <v-icon small>mdi-table-refresh</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{
          $t('forms.tableReviewQuestion')
        }}</v-list-item-title>
      </v-list-item>

      <v-list-item link @click="addTargetsElement">
        <v-list-item-icon>
          <v-icon small>mdi-trophy-award</v-icon>
        </v-list-item-icon>
        <v-list-item-title> {{ $t('targets.newTargets') }} </v-list-item-title>
      </v-list-item>

      <v-list-item link @click="addActionsElement">
        <v-list-item-icon>
          <v-icon small>mdi-trophy-award</v-icon>
        </v-list-item-icon>
        <v-list-item-title> {{ $t('targets.newActions') }} </v-list-item-title>
      </v-list-item>

      <template v-if="customSettings">
        <v-list-item
          v-for="(item, index) in customSettings.customElements"
          :key="`custom_question_${index}`"
          link
          @click="addCustomElement(item)"
        >
          <v-list-item-icon>
            <v-icon small>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ localize(item.title) }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { formEnums } from '@/enums/forms.js';
import { v4 as uuidv4 } from 'uuid';
import { mapState } from 'vuex';

export default {
  data: () => ({
    elementType: formEnums.formElements
  }),

  computed: {
    ...mapState('forms', ['customSettings'])
  },

  methods: {
    addActionsElement() {
      this.$emit('addElement', {
        id: uuidv4(),
        type: this.elementType.ACTIONS,
        title: [],
        subtitle: [],
        labels: []
      });
    },

    addCustomElement(element) {
      this.$emit('addElement', {
        id: uuidv4(),
        type: this.elementType.CUSTOM,
        customComponent: element.component,
        title: element.title,
        subtitle: element.description
      });
    },

    addTextQuestion() {
      this.$emit('addElement', {
        id: uuidv4(),
        type: this.elementType.QUESTION,
        useScale: false,
        allowComment: true,
        labels: [],
        title: [],
        subtitle: [],
        commentFieldType: 1
      });
    },

    addScaleQuestion() {
      this.$emit('addElement', {
        id: uuidv4(),
        type: this.elementType.QUESTION,
        useScale: true,
        maxValue: 5,
        allowComment: true,
        labels: [],
        title: [],
        subtitle: [],
        commentFieldType: 1
      });
    },

    addQuestionMatrix() {
      this.$emit('addElement', {
        id: uuidv4(),
        type: this.elementType.MATRIX,
        maxValue: 5,
        allowComment: true,
        labels: [],
        title: [],
        subtitle: [],
        items: []
      });
    },

    addTargetsElement() {
      this.$emit('addElement', {
        id: uuidv4(),
        type: this.elementType.TARGETS,
        title: [],
        subtitle: [],
        labels: []
      });
    },

    addTableQuestion() {
      this.$emit('addElement', {
        id: uuidv4(),
        type: this.elementType.TABLE,
        title: [],
        subtitle: [],
        labels: []
      });
    },

    addTableReviewQuestion() {
      this.$emit('addElement', {
        id: uuidv4(),
        type: this.elementType.TABLE_REVIEW,
        title: [],
        subtitle: [],
        labels: []
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.v-btn {
  background-color: var(--v-background-base) !important;
}
</style>
